import React from "react"
import { Link, graphql } from "gatsby"
import NomadsImage from '../components/nomads-image'

import Layout from "../components/layout"
import Image from "../components/image"
// import BG from '../assets/images/pages/home/bg.svg';
import {ReactComponent as BG} from '../assets/images/pages/home/bg.svg';
import SEO from "../components/seo"
import '../assets/styles/pages/home.scss';

const IndexPage = (props) => (
  <Layout
      pageID={'home'}
  >
    <SEO title="Home" />
    <div className="bg-wrapper" />

      <section className="top">
    <h1>Nomads Brewing <br/><span>Company</span></h1>
      <div className="img-wrap" style={{width: '33vw', marginRight: 'auto',  marginLeft: 'auto'}}>
      {/*<NomadsImage img={props.data.pic}/>*/}
      </div>
    {/*<p>Now go build something great.</p>*/}

      </section>
  </Layout>
)

export default IndexPage


export const query = graphql`query Homepage ($language: String!) {
  pic: file(relativePath: {eq: "nomads-saison.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 300, layout: CONSTRAINED)
    }
  }
  locales: allLocale(filter: {ns: {in: ["common"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
}
`